import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, funcName } from "packs/common"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import Room from "./Room"
import RoomMember from "./RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import { gtagEvent } from "packs/GoogleTagManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomSetting from "./RoomSetting"
import AvailableScheduleTag from "./AvailableScheduleTag"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import UserFile from "./UserFile"
import lodash from "lodash"
import FormUtil from "packs/utils/FormUtil"

export default class FormField {
    static readonly RELATIVE_TIME_RANGE = `relative_time_range`
    static readonly ABSOLUTE_TIME_RANGE = `absolute_time_range`

    constructor(
        public index_num,
        public field_id,
        public field_type,
        public field_name,
        public description,
        public name_attr,
        public class_attr,
        public placeholder_attr,
        public value_attr,
        public is_required,
        public max_length,
        public minimum_length,
        public selected_values: string[],
        public selectable_values: any[],
        public default_selected_values: string[],
        public param_key: string,
        public is_special: boolean,
        public special_name: string,
        public did_answer: boolean,
        public answered_value: any, // stringまたはstring[]

        // その他に入力があった場合、その入力値はvalue_attrに入る
        public allow_free_text: boolean,
        public canDelete: boolean,
        public hideField: boolean,
        // normal, same_ug_only, same_ug_admin_only
        public display_type: string,
        public change_location_name: boolean,
        // ファイルアップロードしたファイルを一時的に保存.
        public userFile: UserFile,

        public isValid: boolean,
        public invalidText: string,
        public isDisabled: boolean,
        public public_id: string,
        public option_hash: any
    ) {}

    static copy(o: FormField): FormField {
        // let newO = lodash.cloneDeep(o)
        // return newO
        return JSON.parse(JSON.stringify(o))
    }

    static fetchFromJson(groupsJson: any): FormField[] {
        let fields = []
        // let indexnum = 0
        for (const _ff of groupsJson) {
            let indexnum = _ff.index_num
            let ff = this.createDefault(indexnum)
            let newFf = _ff as FormField
            if (Util.isPresent(newFf.default_selected_values)) {
                newFf.selected_values = [...newFf.default_selected_values]
            }
            if (newFf.field_type == `select`) {
                if (newFf.allow_free_text) {
                    if (!(newFf.selectable_values || []).includes(`その他`)) {
                        newFf.selectable_values.push({ value: `その他`, image_url: null })
                    }
                }
                // else {
                //     newFf.selectable_values = (newFf.selectable_values || []).filter(s => s != `その他`)
                // }
            }
            const returnedFF = Object.assign(ff, newFf)
            fields.push(returnedFF)
        }
        return fields
    }

    static readonly selectableTypes: string[] = [`radio`, `select`, `checkbox`]
    static readonly selectableWithImageTypes: string[] = [`radio_image`, `checkbox_image`]
    static readonly specialParamKeys: string[] = [
        `attendee1_name`,
        `attendee1_email`,
        `attendee1_company_name`,
        `attendee1_cc_emails`,
    ]

    /**
     *  field_name: '会社名', 
        name_attr: 'company_name',
        field_type: FieldType::INPUT,
        placeholder_attr: 'waaq株式会社'
     * @param indexNum 
     */
    static createDefault(indexNum = 0): FormField {
        let publicId = FormUtil.generateRamdom()
        return new FormField(
            indexNum,
            null,
            `input`,
            ``,
            null,
            null,
            null,
            ``,
            ``,
            false,
            -1,
            -1,
            [],
            [],
            [],
            null,
            false,
            null,
            false,
            null,
            false,
            true,
            false,
            `normal`,
            false,
            null,
            true,
            ``,
            false,
            publicId,
            {}
        )
    }

    static createNameField(indexNum = 0) {
        let _f = this.createDefault(indexNum)
        _f.canDelete = false
        _f.field_name = `お名前`
        _f.placeholder_attr = `山田 太郎`
        _f.name_attr = `attendee1_name`
        _f.param_key = `attendee1_name`
        _f.is_required = true
        _f.is_special = true
        _f.special_name = `お名前`
        return _f
    }

    static createCompanyField(indexNum = 0) {
        let _f = this.createDefault(indexNum)
        _f.canDelete = true
        _f.field_name = `会社名`
        _f.placeholder_attr = `waaq株式会社`
        _f.name_attr = `attendee1_company_name`
        _f.param_key = `attendee1_company_name`
        _f.is_required = false
        _f.is_special = true
        _f.special_name = `会社名`
        return _f
    }

    static createCCEmailsField(indexNum = 0) {
        let _f = this.createDefault(indexNum)
        _f.canDelete = true
        _f.field_name = `CCメールアドレス`
        _f.placeholder_attr = `yamada@example.com, taro@example.com`
        _f.name_attr = `attendee1_cc_emails`
        _f.param_key = `attendee1_cc_emails`
        _f.is_required = false
        _f.is_special = true
        _f.special_name = `CCメールアドレス`
        _f.description = `追加で通知したいメールアドレスがありましたらご指定ください。（カンマ区切りで複数入力可）`
        return _f
    }

    static createEmailField(indexNum = 0) {
        let _f = this.createDefault(indexNum)
        _f.canDelete = false
        _f.field_name = `メールアドレス`
        _f.placeholder_attr = `yamada@example.com`
        _f.name_attr = `attendee1_email`
        _f.param_key = `attendee1_email`
        _f.is_required = true
        _f.is_special = true
        _f.special_name = `メールアドレス`
        return _f
    }

    static createTelField(indexNum = 0) {
        let _f = this.createDefault(indexNum)
        _f.canDelete = true
        _f.field_name = `電話番号`
        _f.placeholder_attr = `07022222222`
        _f.name_attr = `attendee1_tel`
        _f.param_key = `attendee1_tel`
        _f.is_required = false
        _f.is_special = true
        _f.special_name = `電話番号`
        _f.description = `電話番号を入力ください。-（ハイフン）不要`
        return _f
    }

    /**
     * チャットする際に必要な項目を返します.
     * @return FormField[]
     */
    static createChatFields(): FormField[] {
        return [this.createNameField(0), this.createEmailField(1)]
    }

    /**
     * 設定時に最初に表示する項目を返します.
     * @return FormField[]
     */
    static createDefaultFields(): FormField[] {
        return [this.createNameField(0), this.createEmailField(1), this.createCompanyField(2)]
    }

    static updateIndexNum(formFields: FormField[]): FormField[] {
        let num = 0
        let ffs = Util.isPresent(formFields) ? [...formFields] : []
        for (let _f of ffs) {
            _f.index_num = num
            num += 1
        }
        return ffs
    }

    static isValidField(field: FormField): boolean {
        let _f = field
        if (!_f.is_required) return true
        Logger(`${funcName()} ${Util.output(_f)}`)

        let isInvalid = false
        if ([`input`, `textarea`].includes(_f.field_type)) {
            isInvalid = Util.isBlank(_f.value_attr) ? true : false
            if (Util.isPresent(_f.name_attr) && _f.name_attr.includes(`attendee1_email`)) {
                // email
                let isValid = Util.validateEmail(_f.value_attr)
                isInvalid = !isValid
            } else if (Util.isPresent(_f.name_attr) && _f.name_attr.includes(`attendee1_tel`)) {
                // email
                let isValid = FormUtil.validateTel(_f.value_attr)
                isInvalid = !isValid
                if (isInvalid) {
                    _f.invalidText = `電話番号を正しく入力してください。`
                }
            }
        } else if (
            FormField.selectableTypes.includes(_f.field_type) ||
            FormField.selectableWithImageTypes.includes(_f.field_type)
        ) {
            isInvalid = Util.isBlank(_f.selected_values) ? true : false
            if (Util.isPresent(_f.selected_values) && _f.selected_values.includes(`その他`) && _f.allow_free_text) {
                isInvalid = Util.isPresent(_f.value_attr) ? false : true
                _f.invalidText = `「その他」を選択した場合、詳細な理由を入力してください。`
            }
        } else if (_f.field_type == `birthdate`) {
            // isInvalid = Util.isBlank(_f.selected_values) ? true : false
            if ((_f.selected_values || []).length < 3) {
                isInvalid = true
            } else {
                let year = _f.selected_values[0]
                let month = _f.selected_values[1]
                let day = _f.selected_values[2]
                let birthdateOption = (_f?.option_hash?.field_setting_options || []).find(o =>
                    [FormField.ABSOLUTE_TIME_RANGE, FormField.RELATIVE_TIME_RANGE].includes(o.type)
                )

                Logger(`FormField.isValidField birthdate year:${year}, month:${month}, day:${day}, isInvalid:${isInvalid}`)
                if (Util.isBlank(year) || Util.isBlank(month) || Util.isBlank(day)) {
                    isInvalid = true
                } else if ([`4`, `6`, `9`, `11`].includes(month) && Number(day) >= 31) {
                    isInvalid = true
                } else if (month == `2` && Number(day) >= 30) {
                    isInvalid = true
                } else {
                    isInvalid = false
                }
                if (birthdateOption) {
                    let { st, et } = FormField.birthdateStartToEnd(birthdateOption)
                    let birthdate = DateTime.fromObject({
                        year: Number(year),
                        month: Number(month),
                        day: Number(day),
                    })
                    if (birthdateOption.restrict_date) {
                        if (st > birthdate || birthdate > et) {
                            isInvalid = true
                        }
                    }
                }
            }
            if (isInvalid) {
                _f.invalidText = `正しく入力してください。`
            }
        } else if (_f.field_type == `address`) {
            if ((_f.selected_values || []).length < 4) {
                isInvalid = true
            } else {
                let prefec = _f.selected_values[1]
                let address1 = _f.selected_values[2]

                if (Util.isBlank(prefec) || Util.isBlank(address1)) {
                    isInvalid = true
                } else {
                    isInvalid = false
                }
            }
            if (isInvalid) {
                _f.invalidText = `正しく入力してください。`
            }
        } else if (_f.field_type.includes(`upload_file`)) {
            if (Util.isBlank(_f.value_attr)) {
                isInvalid = true
                _f.invalidText = `ファイルをアップロードしてください。`
            }
        }
        if (!isInvalid) {
            _f.invalidText = null
        }
        Logger(`isValidField: ${!isInvalid}, ${_f.field_name}`)
        return !isInvalid
    }

    static toSendParams(ffs: FormField[], keyType: string = "param_key") {
        let form_params = {}
        if (!ffs) return form_params

        ffs.forEach(_field => {
            let key = _field.param_key
            if (keyType == "public_id") {
                key = _field.public_id
            }

            if (
                FormField.selectableTypes.includes(_field.field_type) ||
                FormField.selectableWithImageTypes.includes(_field.field_type)
            ) {
                if ([`checkbox`, `checkbox_image`].includes(_field.field_type)) {
                    // arrayで送信.
                    // if (_field.selected_values.includes(`その他`)) {
                    //     let index = _field.selected_values.indexOf(`その他`)
                    //     let val = Util.isPresent(_field.value_attr) ? _field.value_attr : "その他"
                    //     _field.selected_values.splice(index, 1, val)
                    // }
                    form_params[`${key}`] = _field.selected_values
                } else {
                    // strで送信.
                    let val = _field.selected_values[0] || []
                    // if (val.includes(`その他`)) {
                    //     val = Util.isPresent(_field.value_attr) ? _field.value_attr : "その他"
                    // }
                    form_params[`${key}`] = val
                }
            } else if (_field.field_type == `birthdate`) {
                let val = _field.selected_values.join("-")
                form_params[`${key}`] = val
            } else if (_field.field_type == `address`) {
                let val = _field.selected_values.join("\n")
                form_params[`${key}`] = val
            } else {
                form_params[`${key}`] = _field.value_attr
            }
        })
        return form_params
    }

    /**
     * 送信して内容を完了させます。
     * @param room
     * @param startTime
     * @param startTimes
     * @param pdm
     * @param nextstep
     * @param isForce 予定が入っていても強制確定したい場合
     * @param isFixThroughReschedule リスケして確定をワンステップで行います.
     * @param message
     * @returns
     */
    static send(
        room: Room,
        startTime: number,
        startTimes: number[],
        pdm: typeof PossibleDatesManager,
        nextstep,
        isForce = false,
        isFixThroughReschedule = false,
        userMessage = null,
        onlineAccs = null,
        registrableAttendees = null,
        tzDic = null,
        rdlog_id = null,
        accessParams = {}
    ) {
        Logger(`送信するform_fields情報: ${Util.output(room.form_fields)}`)
        let form_params = FormField.toSendParams(room.form_fields)

        let params = {
            id: room.id,
            start_time: startTime,
            start_times: startTimes,
            form: form_params,
            is_force: isForce,
            is_fix_through_reschedule: isFixThroughReschedule,
            user_message: userMessage,
            registrable_attendees: registrableAttendees,
            rdlog_id: rdlog_id,
            access_params: accessParams,
        }
        if (tzDic) {
            params[`timezone_value`] = tzDic.value
        }

        // 場所を指定.
        if (Util.isPresent(room.current_appointment)) {
            let _appo = room.current_appointment
            if (Util.isPresent(_appo.location_tag_id)) {
                params[`location_tag_id`] = _appo.location_tag_id
            } else if (Util.isPresent(_appo.location_name)) {
                params[`location_name`] = _appo.location_name
            }

            if (_appo.duration) {
                params[`duration`] = _appo.duration
            }
        }

        if (Util.isPresent(pdm.selectedMag)) {
            params[`group_id`] = pdm.selectedMag.id
        }

        if (Util.isPresent(onlineAccs)) {
            params[`force_online_accounts`] = onlineAccs
        }

        let endpoint = `public_rooms`
        // if (room && room.room_setting && room.room_setting.is_instant_room) {
        //     endpoint = `instant_rooms`
        // }
        if (!Util.isPublic()) {
            endpoint = `rooms`
        }

        Logger(`FormField.send 送信するparams:${Util.output(params)}, endpoint: ${endpoint}`)
        gtagEvent(`fix_or_s}hare`, `${endpoint}`, `${Util.output(params)}`)

        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/${endpoint}/fix_or_share`,
            data: params,
        })
            .then(res => {
                Logger(`FormField.send res.message:${res.data.message}, res.data:${Util.output(res.data)}`)
                if (nextstep == `complete` && res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const userInfo: RoomMember = RoomMember.fetchFromJson([res.data.user_info])[0]
                const res_room: Room = Room.fetchFromJson([res.data.room], userInfo.user_id)[0]

                // 停止に変更してリダイレクト先がある場合、もろもろ変更する前にリダイレクト.
                if (res_room.progress_status == `stay`) {
                    if (RoomSetting.isRedirectUrl(res_room)) {
                        let url = res_room.room_setting.thanks_page.redirect_url_after_send
                        location.href = Util.getUrl(url)

                        return false
                    }
                }
                const roomLink: string = res.data.room_link
                let guestKey = null
                if (roomLink) {
                    if (Util.isEmbeds()) {
                        guestKey = roomLink.split(`/s/`)[1]
                    } else {
                        history.replaceState("", "", `${roomLink}`)
                    }
                }
                let astag: AvailableScheduleTag = null
                if (Util.isPresent(res.data.astag)) {
                    astag = AvailableScheduleTag.fetchFromJson([res.data.astag])[0]
                }

                let registered_attendees = []

                if (Util.isPresent(res.data.registered_attendees)) {
                    registered_attendees = res.data.registered_attendees
                }

                let show_attendees_modal = res.data.show_attendees_modal
                let next_action = res.data.next_action
                let type = res.data.type
                return {
                    userInfo,
                    room: res_room,
                    astag,
                    registered_attendees,
                    show_attendees_modal,
                    next_action,
                    type,
                    guestKey,
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static fillUserInfo(userInfo: RoomMember, _fields: FormField[], changeHideIfExist = true): any {
        Logger(`${funcName()}`)
        if (Util.isBlank(_fields)) return []
        // フィールド内に特殊フィールドがあるかを確認.
        let specialFields = []
        for (let _f of _fields) {
            if (_f.is_special) {
                specialFields.push(_f)
            }
        }
        let loggedinFields = []

        if (Util.isPresent(specialFields)) {
            for (let sf of specialFields) {
                if (sf.param_key == `attendee1_name`) {
                    if (Util.isPresent(userInfo.name)) {
                        let _f = FormField.createNameField()
                        _f.value_attr = userInfo.name
                        _f.isDisabled = true
                        loggedinFields.push(_f)
                    }
                }

                if (sf.param_key == `attendee1_email`) {
                    if (Util.isPresent(userInfo.email)) {
                        let _f = FormField.createEmailField()
                        _f.value_attr = userInfo.email
                        _f.isDisabled = true
                        loggedinFields.push(_f)
                    }
                }

                if (sf.param_key == `attendee1_company_name`) {
                    if (Util.isPresent(userInfo.company_name)) {
                        let _f = FormField.createCompanyField()
                        _f.value_attr = userInfo.company_name
                        _f.isDisabled = true
                        loggedinFields.push(_f)
                    }
                }
            }
        }

        if (changeHideIfExist) {
            for (let _f of loggedinFields) {
                let _fi = _fields.find(f => _f.param_key == f.param_key)
                if (_fi) {
                    _fi.value_attr = _fi.value_attr
                    _fi.hideField = true
                }
            }
        }

        return loggedinFields
    }

    static birthdateStartToEnd(birthdateOption: any) {
        if (birthdateOption.type == FormField.RELATIVE_TIME_RANGE) {
            let st = DateTime.local().plus({ days: birthdateOption.start_day })
            let et = DateTime.local().plus({ days: birthdateOption.end_day })
            return { st, et }
        } else if (birthdateOption.type == FormField.ABSOLUTE_TIME_RANGE) {
            let st = DateTime.fromSeconds(birthdateOption.start_at)
            let et = DateTime.fromSeconds(birthdateOption.end_at)
            return { st, et }
        }
        return { st: null, et: null }
    }
}
